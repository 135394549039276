import React, { useEffect, useState } from 'react';
import { renderContent } from './helperTruncated';

const HanZiURLBlog = ({}) => {
  const [content, setContent] = useState('');
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    fetch('/blog/hanziurl.txt')
      .then(response => response.text())
      .then(blogContent => {
        setContent(blogContent);
        localStorage.setItem('blogContent', blogContent);
      })
      .catch(error => {
        // Handle any errors fetching the content
      });
  }, []);

  // Toggle and Render Functions
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  // Setting
  const num_words = 300;
  const type = "cn";
  const renderDiv = renderContent(toggleContent, content, num_words, type);
  
  return (
    <div>
      <p>{renderDiv}</p>
        <button
          className="blog-button"
          onClick={() => window.open('https://www.maiyuren.com/dict/', '_blank')}
       >
          素典
      </button>
    </div>
  );
};

export default HanZiURLBlog;