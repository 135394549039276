import React from 'react';
import '../assets/skillsets.css'; 


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faComputer, faBook, faPaintbrush } from '@fortawesome/free-solid-svg-icons';


function SkillSets() {  


    // const [currentQuote, setCurrentQuote] = useState(
    //     "We are not always ready, but we always have the power to change.");

    const currentQuote = "We are not always ready, but we always have the power to change.";
    // const [quotes] = [
    //     "We are not always ready, but we always have the power to change.",
    //     "You must free your ambitious mind and learn the art of dying."
    // ];

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    //     }, 8000);
    //     return () => clearInterval(interval);
    // }, [quotes]); 



  return (
    <div id="skillset-container" className="skillset-container">

        <div className='skillset-left'>
            <div className="skills-boxes">

                <div className="skill-box">
                
                    <p className="skill-title">
                        <FontAwesomeIcon icon={faBolt} />
                        &nbsp;
                        Electronic Engineering
                    </p>
                    <p className="skill1-content">
                        HKUST, Knowledges including
                        <br></br>
                        Maxwell Electromagnetic Equation, 
                        Optical Fiber Communication,
                        BJT & MOSFET Transistor, Multistage Amplifier,
                        Diodes, Resistors, Capacitors, Inductors,
                        State Machine, Fourier Transform
                    </p>
                    
                </div>
                
                <div className="skill-box">
                    <p className="skill-title">
                        <FontAwesomeIcon icon={faComputer} />
                        &nbsp;
                        Computer Science
                    </p>
                    <p className="skill1-content">
                        HKUST, Knowledges including
                        <br></br>
                        Algorithms Anlysis, Machine Learning,
                        Web Development (React.js), Python, C++, Flutter, 
                        Multimedia Processing
                    </p>
                </div>

            </div>
            <div className="skills-boxes-2">

                <div className="skill-box">
                    <p className="skill-title">
                        <FontAwesomeIcon icon={faBook} />
                        &nbsp;
                        Chinese Cultures
                    </p>
                    <p className="skill1-content">
                        Familiar with
                        <br></br>
                        Chinese Calligraphy - 書灋
                        <br></br>
                        Shuowen JieZi - 說文解字注, Glyph - 甲骨文
                        <br></br>
                        Jupiter Calendar - 干支紀年曆法
                        <br></br>
                        Feng Shui 形巒 - 撼龍經
                    </p>

                </div>
                <div className="skill-box">
                    <p className="skill-title">
                        <FontAwesomeIcon icon={faPaintbrush} />
                        &nbsp;
                        Graphic Design
                    </p>
                    <p className="skill1-content">
                        Design Tools - Paint.net
                        <br></br>
                        Experience: Promotion Secretary in HKUST CFAS
                    </p>
                </div>


            </div>
        </div>

        <div className='skillset-right'>
            "{currentQuote}"
            
        </div>
  </div>
  );
}

export default SkillSets;